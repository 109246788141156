const acolumns = [
  {
    title: 'No',
    slots: { customRender: 'no' },
  },
  {
    title: 'Kode Toko',
    dataIndex: 'vendor_code',
  },
  {
    title: 'Nama Toko',
    dataIndex: 'vendor_name',
  },
  {
    title: 'Kode Program',
    dataIndex: 'program_code',
  },
  {
    title: 'Nama Program',
    dataIndex: 'program_name',
  },
  {
    title: 'UOM',
    dataIndex: 'uom',
  },
  {
    title: 'Kuartal',
    dataIndex: 'quartal',
  },
  {
    title: 'Deskripsi',
    dataIndex: 'description',
  },
  {
    title: 'Tanggal',
    dataIndex: 'date',
    slots: { customRender: 'formatDate' },
  },
  {
    title: 'Poin',
    dataIndex: 'points',
  },
  {
    title: 'Status Poin',
    dataIndex: 'point_uomname',
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]
export default acolumns
